import React from 'react'
import Bookpass from '../components/Book-passes/Bookpass'
import "./boospassescss.css"
const BookPassPage = () => {
  return (
    <div className="boospassescss">
    <Bookpass />
    </div>
  )
}

export default BookPassPage