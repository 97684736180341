import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select"; // Make sure to install react-select
import instance from "../../axios";
import "./viewpass.css";

const TableWithSearchAndFilter = () => {
  // Data
  const [data, setData] = useState([]);
  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const [selectedPhases, setSelectedPhases] = useState([]);
  const [couponOptions, setCouponOptions] = useState([]);
  const [phaseOptions, setPhaseOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitToken(inputValue);
  };

  const submitToken = async (token) => {
    try {
      const response = await instance.get(`token?token=${token}`);
      if (response.data.data) {
        const convertedToken = btoa(token);
        sessionStorage.setItem("authToken", convertedToken);
        setIsOpen(!response.data.data);
      } else {
        setIsOpen(true);
        toast.error("Wrong Password");
      }
    } catch (error) {
      toast.error("Error Get Token. Please try again.");
    }
  };

  useEffect(() => {
    const storedToken = sessionStorage.getItem("authToken");
    if (storedToken) {
      const originalToken = atob(storedToken);
      submitToken(originalToken);
    } else {
      setIsOpen(true);
      setData([]);
    }
  }, []);

  useEffect(() => {
    const getCouponData = async () => {
      try {
        const response = await instance.get("list_of_coupon");
        setCouponOptions(
          response.data.map((coupon) => ({ value: coupon, label: coupon }))
        );
      } catch (error) {
        toast.error("Error Get Coupon. Please try again.");
      }
    };

    const getPhaseData = async () => {
      try {
        const response = await instance.get("list_of_phase");
        setPhaseOptions(
          response.data.map((phase) => ({
            value: phase,
            label: `Phase ${phase}`,
          }))
        );
      } catch (error) {
        toast.error("Error Get Phase. Please try again.");
      }
    };

    if (!isOpen) {
      getCouponData();
      getPhaseData();
    }
  }, [isOpen]);

  useEffect(() => {
    const getPassesData = async () => {
      try {
        setLoading(true); // Start the loader
        const response = await instance.post("total_passes", {
          coupon: selectedCoupons.map((coupon) => coupon.value),
          phase: selectedPhases.map((phase) => phase.value),
        });
        setData(response.data.data);
      } catch (error) {
        toast.error("Error Get Passes. Please try again.");
      } finally {
        setLoading(false); // Stop the loader
      }
    };
    const storedToken = sessionStorage.getItem("authToken");

    if (storedToken) {
      getPassesData();
    }
  }, [isOpen, selectedCoupons, selectedPhases]);

  return (
    <>
      <div className="w-full sm:w-3/4 mx-auto p-6 bg-transparent rounded-lg">
        {/* Multi-Selects */}
        <div className="block md:flex mt-10 whitespace-nowrap justify-start gap-4 mb-6">
          <div>
            <Select
              options={couponOptions}
              isMulti
              onChange={setSelectedCoupons}
              placeholder="Select Coupons"
              className="w-fit"
              styles={{
                control: (base) => ({
                  ...base,
                  backgroundColor: "#730000",
                  color: "white",
                  borderColor: "#730000",
                  boxShadow: "none",
                  "&:hover": { borderColor: "#8B0000" },
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: "#730000",
                  color: "white",
                }),
                option: (base, { isFocused, isSelected }) => ({
                  ...base,
                  backgroundColor: isSelected
                    ? "#8B0000"
                    : isFocused
                    ? "#590000"
                    : "#730000",
                  color: "white",
                  "&:hover": { backgroundColor: "#8B0000" },
                }),
                placeholder: (base) => ({ ...base, color: "white" }),
                singleValue: (base) => ({ ...base, color: "white" }),
              }}
            />
          </div>
          <div>
            <Select
              options={phaseOptions}
              isMulti
              onChange={setSelectedPhases}
              placeholder="Select Phases"
              className="w-fit mt-4 md:mt-0"
              styles={{
                control: (base) => ({
                  ...base,
                  backgroundColor: "#730000",
                  color: "white",
                  borderColor: "#730000",
                  boxShadow: "none",
                  "&:hover": { borderColor: "#8B0000" },
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: "#730000",
                  color: "white",
                }),
                option: (base, { isFocused, isSelected }) => ({
                  ...base,
                  backgroundColor: isSelected
                    ? "#8B0000"
                    : isFocused
                    ? "#590000"
                    : "#730000",
                  color: "white",
                  "&:hover": { backgroundColor: "#8B0000" },
                }),
                placeholder: (base) => ({ ...base, color: "white" }),
                singleValue: (base) => ({ ...base, color: "white" }),
              }}
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-transparent border border-red-700 rounded-lg shadow-sm">
            <thead>
              <tr className="bg-transparent text-black uppercase text-xl whitespace-nowrap">
                <th className="py-3 px-6 border-b border-red-700">Day</th>
                <th className="py-3 px-6 border-b border-red-700">Quantity</th>
                <th className="py-3 px-6 border-b border-red-700">
                  Base Amount
                </th>
                <th className="py-3 px-6 border-b border-red-700">
                  GST Amount
                </th>
                <th className="py-3 px-6 border-b border-red-700">
                  Total Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((row, index) => (
                  <tr
                    key={index}
                    className="text-center text-xl bg-transparent text-black font-semibold"
                  >
                    <td className="py-3 px-6 border-b border-red-700">
                      {row.Day}
                    </td>
                    <td className="py-3 px-6 border-b border-red-700">
                      {row.Quantity}
                    </td>
                    <td className="py-3 px-6 border-b border-red-700">
                      {row["Base Amount"]}
                    </td>
                    <td className="py-3 px-6 border-b border-red-700">
                      {row["GST Amount"]}
                    </td>
                    <td className="py-3 px-6 border-b border-red-700">
                      {row["Total Amount"]}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    className="py-4 text-center text-white text-xl font-semibold"
                  >
                    {loading ? (
                      <div className="text-center my-6">
                        <div className="loader inline-block w-10 h-10 border-4 border-t-transparent border-red-700 rounded-full animate-spin"></div>
                      </div>
                    ) : (
                      "No data found"
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {isOpen && (
        <div className="p-6 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-2xl mb-4 text-center">Enter Password</h2>

            {/* Modal form */}
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Enter Password"
                className="w-full border border-gray-300 rounded p-2 mb-4 focus:outline-none focus:ring focus:border-blue-500"
              />
              <div className="flex justify-between">
                <button
                  style={{
                    backgroundColor: "#730000",
                  }}
                  type="submit"
                  className=" text-white px-4 py-2 rounded  transition"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default TableWithSearchAndFilter;
