import React from "react";
import "./MainBanner.css";
import { Link } from "react-router-dom";
import bannerImage from "../../images/logo.png";
const MainBanner = () => {
  return (
    <div className="banner-content">
      {/* <h2 className="coming-soon">03-12th Oct</h2> */}
      <div className="banner-text">
        <h1>સૂર્યાસ્ત થી સૂર્યોદય સુધી</h1>
      </div>
      {/* <button className="book-passes">
        <Link to="/book-passes">Book Passes</Link>
      </button>{" "} */}
    </div>
  );
};

export default MainBanner;
