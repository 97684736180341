import React from "react";
import MainBanner from "../components/Home/MainBanner";
import "./home.css";

const App = () => {
  return (
    <div className="main-banner-main">
      <MainBanner />
    </div>
  );
};

export default App;
