import React, { useState, useCallback, useRef, useEffect } from "react";
import Slider from "react-slick";
import { useInView } from "react-intersection-observer";
import "./Gallery.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import instance from "../../axios"; // Assuming this is configured to point to your backend
import { toast } from "react-toastify";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Loader component
const Loader = () => <div className="loader">Loading...</div>;

const Gallery = () => {
  const [images, setImages] = useState([]); // All fetched images
  const [displayedImages, setDisplayedImages] = useState([]); // Images displayed
  const [page, setPage] = useState(1); // Pagination state
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // To control when there are no more images

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 1.0,
  });

  // Fetch images from the backend API
  const getImages = async () => {
    try {
      setLoading(true);
      const response = await instance.get("/images"); // Replace with your API endpoint
      const fetchedImages = response.data.data; // Assuming response structure is { data: { data: [...] } }

      // If the API returned fewer than 3 images, it means there are no more images to load
      if (fetchedImages.length === 0) {
        setHasMore(false);
      } else {
        setImages((prevImages) => [...prevImages, ...fetchedImages]);
      }
    } catch (error) {
      toast.error("Error loading images. Please try again."); // Display error toast
    } finally {
      setLoading(false);
    }
  };

  // Function to load more images when the user scrolls
  const loadMoreImages = useCallback(() => {
    if (hasMore) {
      setLoading(true);
      setDisplayedImages((prevDisplayed) => {
        const newPage = page + 1;
        const newImages = images.slice(0, newPage * 3); // Load 3 more images
        setPage(newPage);
        setLoading(false);
        return newImages;
      });
    }
  }, [images, page, hasMore]);

  useEffect(() => {
    getImages(); // Initial image fetch
  }, []);

  useEffect(() => {
    if (inView && !loading && hasMore) {
      loadMoreImages(); // Load more images when in view
    }
  }, [inView, loadMoreImages, loading, hasMore]);

  // Slider settings
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    loading :true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleImageClick = (src) => {
    // Redirect to Instagram page
    window.open("https://www.instagram.com/garbinaagarba_/", "_blank");
  };

  return (
    <>
      <div className="gallery">
        <Slider {...sliderSettings}>
          {displayedImages.map((src, index) => (
            <div
              className="gallery-item"
              key={index}
              onClick={() => handleImageClick(src)}
            >
              <LazyLoadImage
                alt={`image-${index}`}
                effect="blur"
                placeholderSrc="/placeholder-image.png"
                src={src}
                wrapperClassName="image-wrapper"
                className="gallery-image"
              />
            </div>
          ))}
        </Slider>
      </div>
      {hasMore && (
        <div ref={ref} style={{ height: "20px" }}></div> // Trigger infinite scroll
      )}
      {loading && <Loader />} {/* Loader while images load */}
    </>
  );
};

export default Gallery;
