import React from 'react'
import Contact from "../components/contact/Contact"
import "./contactpage.css"
const ContactPage = () => {
  return (
    <div className="contactpage">
    <Contact />
    </div>
  )
}

export default ContactPage