import React from "react";
import "./About.css";

const About = () => {
  return (
    <>
      <div className="about">
        <p>
          Amba Mataji is created by the trinity of Bhrama, Vishnu and Shiva, in
          order to battle a powerful demon, named Mahishasura. Garbi 2024, wants
          to potray and make our patrons witness the Power & Magnitude of Mataji
          combined by the forces of our Team, Designers, Sculptors, Artists,
          Agencies we want to create an Once-In-A-Lifetime Experience for all
          visitors; on a 2,00,000+ sq.ft. area decked up with never seen before
          Decor, Vibes, experiences, food and Emotions.
        </p>
        <br />
        <p>
          Historically, Garbi, is the name of a holy pot containing a diya
          inside, during the festival of Navrtari, it is worshipped & regarded
          by people all-across, often little girls / women of families carry a
          Garbi and making ‘Garbi’ a symbol of worship & divine celebration
          <br />
          <br />
          For us and our beloved Patrons also, Garbi is a symbol of Culture,
          Devotion and Celebration! To keep its honor intact and imbibe the
          historical ‘Garba Culture’ of Gujarat and garba amongst the younger
          generations, we wanted it to be very welcoming, with th traditional OG
          tunes of Dhol and Shehnai, immersive Decor, Huge and Grand creatively
          designed Installations, Eye-line vibrant lights and thousands of
          Gujaratis hooked on a singular 8-step melody
        </p>
        <p>
          Garbi defines each quality which Gujarat stands for cultural || social
          || warm
        </p>
        <p>Yaari Group is the organiser of the Garbi Naa Garba 2024</p>

        {/* <div className="team">
          <div className="team-member">
            <img src={pic1} alt="Drashti Patel" />
            <h3>mihir</h3>
            <p>dxfzdfzd</p>
          </div>
          <div className="team-member">
            <img src={pic1} alt="Kavan Berawala" />
            <h3>mihir</h3>
            <p>dxfzdfzd</p>
          </div>
          <div className="team-member">
            <img src={pic1} alt="Sagar Vadher" />
            <h3>mihir</h3>
            <p>dxfzdfzd</p>
          </div>
          <div className="team-member">
            <img src={pic1} alt="Maharshi Patel" />
            <h3>mihir</h3>
            <p>dxfzdfzd</p>
          </div>
          <div className="team-member">
            <img src={pic1} alt="Karan Makwana" />
            <h3>mihir</h3>
            <p>dxfzdfzd</p>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default About;
