import React, { useState } from "react";
import "./contact.css";
import instance from "../../axios";
import { toast } from "react-toastify";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    connect: {
      contentCreation: false,
      sponsorship: false,
      internship: false,
    },
    description: "",
  });

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      connect: {
        ...prevState.connect,
        [name]: checked,
      },
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = [];

    if (!formData.name.trim()) {
      errors.push("Name is required and cannot be just spaces");
    } else if (!/^[a-zA-Z\s]+$/.test(formData.name.trim())) {
      errors.push("Name can only contain letters and spaces");
    }
    const emailPattern =
      /^[a-zA-Z0-9._%+-]+@(gmail\.com|hotmail\.com|google\.com|yahoo\.com|outlook\.com|icloud\.com|google\.co\.in|gmail\.co\.in|yahoo\.co\.in|apple\.com)$/;

    if (!formData.email.trim()) {
      errors.push("Email is required");
    } else if (!emailPattern.test(formData.email)) {
      errors.push(
        "Please use any one of these email provider : gmail.com,hotmail.com, google.com, yahoo.com, outlook.com, icloud.com, google.co.in, gmail.co.in, yahoo.co.in, apple.com"
      );
    }
    const phonePattern = /^[6-9][0-9]{9}$/;
    if (!formData.phoneNumber.trim()) {
      errors.push("Phone number is required.");
    } else if (!phonePattern.test(formData.phoneNumber)) {
      errors.push(
        "Enter a valid 10-digit phone number starting with 6, 7, 8, or 9"
      );
    }
    if (!formData.description.trim()) {
      errors.push("Description is required.");
    }
    if (
      !formData.connect.contentCreation &&
      !formData.connect.sponsorship &&
      !formData.connect.internship
    ) {
      errors.push("At least one type of connection must be selected.");
    }

    // Display errors using toast messages
    errors.forEach((error) => toast.error(error));

    return errors.length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const getConnectArray = () => {
      const { connect } = formData;
      const connectArray = [];
      if (connect.contentCreation) connectArray.push("1");
      if (connect.sponsorship) connectArray.push("2");
      if (connect.internship) connectArray.push("3");
      return connectArray;
    };

    const bodyData = {
      name: formData.name,
      contact_number: formData.phoneNumber,
      email: formData.email,
      collaboration_types: getConnectArray(),
      description: formData.description,
    };

    try {
      const response = await instance.post("/connect_us", bodyData);
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        connect: {
          contentCreation: false,
          sponsorship: false,
          internship: false,
        },
        description: "",
      });
      toast.success("Form submitted successfully!"); // Success toast
    } catch (error) {
      toast.error("Error submitting form. Please try again."); // Error toast
    }
  };

  return (
    <div className="contact-container">
      <form className="contact-form" onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </label>
        <div className="row">
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Phone Number:
            <input
              type="tel"
              name="phoneNumber"
              maxLength={10}
              pattern="\d{10}"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <fieldset className="checkbox-group">
          <legend>Connect with us:</legend>
          <div className="connect-checkboxes">
            <div className="flex">
              <input
                type="checkbox"
                name="contentCreation"
                checked={formData.connect.contentCreation}
                onChange={handleCheckboxChange}
              />
              <p className="mt-auto mb-auto">Content/Creation</p>
            </div>
            <div className="flex">
              <input
                type="checkbox"
                name="sponsorship"
                checked={formData.connect.sponsorship}
                onChange={handleCheckboxChange}
              />
              <p className="mt-auto mb-auto">Sponsorship/Collaboration</p>
            </div>
            <div className="flex">
              <input
                type="checkbox"
                name="internship"
                checked={formData.connect.internship}
                onChange={handleCheckboxChange}
              />
              <p className="mt-auto mb-auto">Internship</p>
            </div>
          </div>
        </fieldset>
        <label>
          Description:
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </label>
        <button type="submit">Submit</button>

        <div className="mt-4 text-lg">
          <p>
            <b> Email :</b> info@garbinaagarba.com
          </p>
          <p>
            <b> Operational Address :</b> B/337, Lilamani Trade Centre,
            PRESS,DUDHESHWAR ROAD, OPP PHITOLITHO, O/S DELHI DARWAJA, Ahmedabad,
            Ahmedabad, GJ, 380004
          </p>
        </div>
      </form>
    </div>
  );
};

export default Contact;
