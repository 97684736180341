import React from 'react'
import "./boospassescss.css"
import Viewpass from 'components/View-passes/Viewpass'
const ViewPassPage = () => {
  return (
    <div className="boospassescss">
    <Viewpass />
    </div>
  )
}

export default ViewPassPage