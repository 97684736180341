import React from "react";
import "./Footer.css";
import { IoCall } from "react-icons/io5";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoFacebook } from "react-icons/io";
import { FaMapMarkerAlt } from "react-icons/fa";
const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-row">
        {/* <div className="footer-text">
          <span className="get-text">Get</span>
          <span className="in-touch-text"> In Touch</span>
        </div> */}
        {/* <div className="footer-contact">
          <div className="contact-row">
            <a href="tel:+918200675050" className="contact-item">
              <IoCall className="contact-icon" />
              +91-8200675050
            </a>
          </div>
        </div> */}
        {/* <div className="footer-address">
          <a
            href="https://maps.app.goo.gl/ZPsoRvhBcqNT8qzN8?g_st=com.google.maps.preview.copy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaMapMarkerAlt className="location-icon" /> Address: Ahmedabad,
            Gujarat, India
          </a>
        </div> */}
        {/* <div className="footer-icons">
          <div className="icon-link">
            <a
              href="https://www.instagram.com/garbinaagarba_/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillInstagram />
            </a>
          </div>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
