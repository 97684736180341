import React from 'react'
import About from "../components/about/About"
import "./About.css"
const AboutPage = () => {
  return (
    <div className='aboutpage'>
   <About />
   </div>
  )
}

export default AboutPage