import Home from './pages/Home';
import AboutPage from './pages/AboutPage';
import GalleryPage from './pages/GalleryPage';
import ContactPage from './pages/ContactPage';
import BookPassPage from './pages/BookPassPage';
import DesignersPage from './pages/DesignersPage';
import Layout from './Layout';
import { Route, Routes } from 'react-router-dom';
import BookpassSoonPage from 'components/Book-passes/BookpassSoonPage';
import ViewPassPage from 'pages/ViewPassPage';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="gallery" element={<GalleryPage />} />
        <Route path="connect" element={<ContactPage />} />
        <Route path="book-passes" element={<BookPassPage />} />
        <Route path="4798507a-ffcd-4579-ab65-37162710c1d7" element={<ViewPassPage />} />
        {/* <Route path="book-passes" element={<BookpassSoonPage />} /> */}
        
        {/* <Route path="designer-call" element={<DesignersPage />} /> */}
      </Route>
    </Routes>
  );
};

export default App;
