import React from 'react'
import Gallery from '../components/gallery/Gallery'
import "./gallerypage.css"
const GalleryPage = () => {
  return (
    <div className="gallerypage">
    <Gallery />
    </div>
)
}

export default GalleryPage