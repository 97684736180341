import React from "react";
import "./Header.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const Header = ({ isMenuOpen, setIsMenuOpen }) => {
  const toggleMenu = () => {
    const isMobile = window.innerWidth <= 767; // Check if the screen width is 767px or less

    if (isMobile) {
      setIsMenuOpen(!isMenuOpen);

      if (!isMenuOpen) {
        document.body.classList.add("menu-open");
      } else {
        document.body.classList.remove("menu-open");
      }
    }
  };

  return (
    <header className="header">
      <div className="menu-icon" onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </div>

      <nav className={`navbar ${isMenuOpen ? "navbar-open" : ""}`}>
        <ul className="navbarList">
          <li>
            <Link to="/" className="navbarAnchor" onClick={toggleMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="navbarAnchor" onClick={toggleMenu}>
              About
            </Link>
          </li>
          <li>
            <Link to="/gallery" className="navbarAnchor" onClick={toggleMenu}>
              Garbi 2023
            </Link>
          </li>
          <li>
            <Link to="/connect" className="navbarAnchor" onClick={toggleMenu}>
              Connect
            </Link>
          </li>
          <li>
            <Link
              to="/book-passes"
              className=" bookpass-button"
              onClick={toggleMenu}
            >
              Book Passes
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
